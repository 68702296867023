import React, { useEffect, useState, useMemo } from "react";
import './pagination.css';

const Pagination = ({
    total = 0,
    itemsPerPage = 10,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li
                    key={i}
                    
                    onClick={() => onPageChange(i)}
                    className={i === currentPage ? 'active' : ''}
                >
                    {i}
                </li>
            );
        }

        return pages;
    }, [totalPages, currentPage]);

    if (totalPages === 0) return null;

    return (
        <ul className="list-pagination">
            <li
                onClick={() => onPageChange(currentPage - 1)}                
            >
                <button disabled={currentPage === 1}>Anterior</button>
            </li>
            {paginationItems}
            <li
                onClick={() => onPageChange(currentPage + 1)}                
            >
               <button disabled={currentPage === totalPages}> Próximo </button>
            </li>
        </ul>
    );
};

export default Pagination;