import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Table from '../../../components/Table/Table';
import Header from '../../../layouts/Header/Header';
import iconTitle from '../../../assets/images/icons/icon-sensores.svg';
import SideMenu from '../../../layouts/SideMenu/SideMenu';
import TitlePrimary from '../../../components/Titles/TitlePrimary/TitlePrimary';
import TitleSecundary from '../../../components/Titles/TitleSecundary/TitleSecundary';
import ModalDelete from '../../../components/Modals/ModalDelete/ModalDelete';
import ModalWarning from '../../../components/Modals/ModalWarning/ModalWarning';
import ModalJustify from '../../../components/Modals/ModalJustify/ModalJustify';

const ListBalancas = () => {
    const [ infoTable, setInfoTable ] = useState([]);
    const [ isVisibleWarning, setIsVisibleWarning] = useState(false)
    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');    
    const access_token = localStorage.getItem('tokencobotics');

    const config = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": "Bearer "+access_token
        }
    }  

    useEffect(() => {

        const getDataTable = () => {
            const header = [
                { name: "ID Equipamento", field: "id_equipamento", sortable: true },
                { name: "Bruto", field: "bruto", sortable: true },
                { name: "Liquido", field: "liquido", sortable: true },
                { name: "Tara", field: "tara", sortable: true },
                { name: "Data", field: "datetime", sortable: true },
                { name: "Dt. Sensor", field: "sensor", sortable: true }
            ];

            axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/balanca/listaBalancas`, { headers: config.headers })
            .then(response => { 

                return response.data;        
            })

            .then(response => {
                setInfoTable({
                    header,
                    body: response,
                })        
            })
            .catch((error) => {
                setIsVisibleWarning(true);           
                setTitle('Erro');
                setDescription('Whoops! Houve um erro no envio.', error.message || error);
            }); 

        }  

        getDataTable();

    }, []);

    const renderTable = useMemo(() => {
        if (infoTable.header !== undefined) {
             return <Table 
              data={infoTable}
              linkButton={"/cadSensor"}
             />
        } 
    }, [ infoTable ]);

  return (
    <section className="content">
      <Header primaryTitle={'Sensores'}/>
      <div className="d-flex">
        <SideMenu />
        <div className="container w-100">
            <TitlePrimary title="Balanças" icon={iconTitle}/>
            <div className="box-default">
              <TitleSecundary title="Registro de Balanças"/>
              {renderTable}
            </div>
        </div>
      </div>
    </section>
  );  
}

export default ListBalancas;