import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import './cad-sensor.css';
import Historico from '../hisInstal/Historico';
import Table from '../../../components/Table/Table';
import Header from '../../../layouts/Header/Header';
import iconSave from '../../../assets/images/icons/icon-salvar.svg';
import SideMenu from '../../../layouts/SideMenu/SideMenu';
import TitleSecundary from '../../../components/Titles/TitleSecundary/TitleSecundary';
import TitlePrimary from '../../../components/Titles/TitlePrimary/TitlePrimary';
import iconTitle from '../../../assets/images/icons/icon-sensores.svg';
import ModalWarning from '../../../components/Modals/ModalWarning/ModalWarning';
import '../../../components/Table/table.css'
export default class CadSensor extends React.Component {
  state = {
    // estado sensores
    tipoSensores: [],
    selectedTipo: '',
    idSensor: '',
    nSerie: '',
    modelo: '',
    fabricante: '',
    ativacao: '',
    clientes: [],
    selectedCliente: [],
    areaNome: '',
    area: [],
    residuoPv: [],
    residuoNome: '',
    arrayNmArea: [],
    geradorPV: '',
    idClientCobotics: '',
    nomeCliente: '',
    nomePersonalizado: '',

    // estado equipamento
    id_equipamento_edit: '',
    equipamento: '',
    fabricEqp: '',
    modeloEqp: '',
    serieEqp: '',
    gsmEqp: '',
    lacreEqp: '',
    ativacaoEqp: '',
    remocaoEqp: '',
    idEquip: '',
    obEquipamento: [],
    clientArray: [],    
    arraynomeEqp: [],
    arrayfabricaEqp: [],
    arraymodeloEqp: [],

    selectedOption: [],
    options: [
      // { id: 1, value: 'chocolate1', label: 'Chocolate' },
      // { id: 2, value: 'strawberry1', label: 'Strawberry' },  
      // { id: 3, value: 'vanilla1', label: 'Vanilla' },
      
    ],
    headerTable: [
      { name: "Cliente", field: "cliente", sortable: true },
      { name: "Área", field: "area", sortable: true },
      { name: "Dt. Instalação", field: "data_instalacao", sortable: true },
      { name: "equipamento", field: "equipamento", sortable: true }
    ],
    infoTable: '',

    modalVisible: false,

    // ID do sensor no banco
    sensorId: this.props.match.params.id,
    
    //State do Material
    headerTableMaterial: [
      { name: "Produto", field: "produto", sortable: true },
      { name: "Material", field: "material", sortable: true },
      { name: "Nome Personalizado", field: "nome", sortable: true }
    ],
    infoMaterialTable: [],
    arrayMaterialPV: [],
    arrayNmMaterial: [],
    arrayNmPersonalizado: [],
    idMaterial: '',
    
    //State do Operador
    headerTableOperador: [
      { name: "Operador", field: "operador", sortable: true },
      { name: "ID do Crachá", field: "cracha", sortable: true }
    ],
    infoOperadorTable: [],
    nomeOperador: '',
    idCracha: '',
    arrayOperador: [],
    arrayCracha: [],

    converte: []
  }

  // chamado na construção da página
  componentDidMount() {
    // const idEdit = this.props.match.params.id;
    // this.setState({sensorId: idEdit});
    if(this.state.sensorId){
      axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/sensor/editSensor/${this.state.sensorId}`)
        .then(response => { 
          this.setState({idSensor: response.data.sensor.id_sensor})
          this.setState({nSerie: response.data.sensor.numero_serie})
          this.setState({modelo: response.data.sensor.modelo_sensor})
          this.setState({fabricante: response.data.sensor.fabricante})
          this.setState({ativacao: response.data.sensor.data_ativacao})
          this.setState({selectedTipo: response.data.sensor.tipo_sensores_id})
          this.setState({selectedArea: response.data.sensor.area_id})
          this.setState({selectedCliente: response.data.sensor.cliente_id})
          // this.setState({obEquipamento: response.data.equipamento})
          // this.setState({equipamento: response.data.equipamento.nome})
          // this.setState({fabricEqp: response.data.equipamento.fabricante})
          // this.setState({modeloEqp: response.data.equipamento.modelo})
          // this.setState({serieEqp: response.data.equipamento.numero_serie})
          this.setState({gsmEqp: response.data.sensor.numero_gsm})
          // this.setState({lacreEqp: response.data.equipamento.lacre})
          this.setState({ativacaoEqp: response.data.sensor.data_instalacao})
          this.setState({remocaoEqp: response.data.sensor.data_remocao})

         

          const variavelParaTesteHistoricoInstalacao = [];
          
          response.data.sensor.rel_clientes_sensor.map((item,indice) => {
            const clientInfo = {
              id_equipamento_edit: response.data.equipamento[indice].id,
              id: response.data.equipamento[indice].id,
              cliente: item.cliente.razao_social,
              area: response.data.equipamento[indice].areas_nome,
              data_instalacao: response.data.sensor.data_instalacao,
              equipamento: response.data.equipamento[indice].equip_nome,
              area_id: response.data.equipamento[indice].areas_id,
              client_id: item.cliente.id,
              fabricante: response.data.equipamento[indice].equip_fabricante,
              modelo: response.data.equipamento[indice].equip_modelo,
            } 

            variavelParaTesteHistoricoInstalacao.push(clientInfo);
          });
          
          this.setState({ infoTable: variavelParaTesteHistoricoInstalacao })

          const retornoMaterial = [];

          // const conv = this.state.converte.concat(response.data.material);

          // this.setState({converte: conv})
          
          response.data.material.map((mat) => {
            const materialInfo = {
              id: mat.id,
              residuo_id: mat.residuo_id_pv,
              material: mat.residuo_nome_pv,
              nome_personalizado: mat.material,
            }

            retornoMaterial.push(materialInfo);
          });

          this.setState({ infoMaterialTable: retornoMaterial })
          
        });        
    }

    // chamada para trazer os dados do Select tipo Sensor
    axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/api/v1/tipoSensor`).then(response => {

      // const tp = response.data.map(tipo => tipo.nome);
      // this.setState({tipoSensores: tp});
      const tp = response.data.map((tipo) =>
        <option key={tipo.id} value={tipo.id}>{tipo.nome}</option>
      );
      this.setState({ tipoSensores: tp });
    });

    // chamada para popular select do cliente
    axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/api/v1/listaClientes`).then(response => {
      const arrayClient = [];
      const listClient = response.data.map((client) =>
        // <option key={client.id} value={client.id} data-ger={client.id_pv} >{client.razao_social}</option>
        
        arrayClient.push({id:client.id, value:client.id_pv, label:client.razao_social})
      );
      this.setState({options: arrayClient });
    })

    axios.get(`${process.env.REACT_APP_PV_BASE_URL}/api/v1/cobotics/listaResiduos?id_gerador=416`)
    .then(resp => {
      this.setState({residuoPv : resp.data});
    })

  }

  // chamdo quando algo é atualizado
  componentDidUpdate(prevProps, prevState) {

    if (prevState.sensorId !== this.state.sensorId) {
      axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/sensor/editSensor/${this.state.sensorId}`)
      .then(response => {        
        this.setState({idSensor: response.data.sensor.id_sensor})
        this.setState({nSerie: response.data.sensor.numero_serie})
        this.setState({modelo: response.data.sensor.modelo_sensor})
        this.setState({fabricante: response.data.sensor.fabricante})
        this.setState({ativacao: response.data.sensor.data_ativacao})
        this.setState({selectedTipo: response.data.sensor.tipo_sensores_id})
        this.setState({selectedArea: response.data.sensor.area_id})
        this.setState({selectedCliente: response.data.sensor.cliente_id})
        this.setState({obEquipamento: response.data.equipamento})
        
        
        this.setState({equipamento: response.data.equipamento.nome})
        this.setState({fabricEqp: response.data.equipamento.fabricante})
        this.setState({modeloEqp: response.data.equipamento.modelo})
        this.setState({serieEqp: response.data.equipamento.numero_serie})
        this.setState({gsmEqp: response.data.equipamento.numero_gsm})
        this.setState({lacreEqp: response.data.equipamento.lacre})
        this.setState({ativacaoEqp: response.data.equipamento.data_ativacao})
        this.setState({remocaoEqp: response.data.equipamento.data_remocao})
      });         
    }

    if (prevState.infoTable !== this.state.infoTable) {
console.log(this.state.infoTable);
      this.renderTbody()
    } 

    if (prevState.infoMaterialTable !== this.state.infoMaterialTable) {
      this.renderTableMaterial();
    }  

    if (prevState.infoOperadorTable !== this.state.infoOperadorTable) {
      this.renderTableOperador();
    }  
  }

  async selectSensor(infos) {        

    /** cria um estado para o id na edição */
    this.setState({ id_equipamento_edit: infos.id })
    this.setState({ selectedOption: infos.client_id })
    this.setState({ equipamento: infos.equipamento })
    this.setState({ fabricEqp: infos.fabricante })
    this.setState({ modeloEqp: infos.modelo })
    this.setState({ nomeCliente: infos.cliente })
    this.setState({ idEquip: infos.id })
    this.setState({ areaNome: infos.area })

    const id_ger_pv = await  document.querySelector("#client");
    const id_plataformaverde = await id_ger_pv.options[id_ger_pv.selectedIndex].dataset.plataformaverde;

    await this.handleSelectedCliente(infos.client_id, id_plataformaverde ,infos.cliente);

    await this.setState({ selectedArea: infos.area_id });
  }

  async selectMaterial(infos) {  
    this.setState({ nomePersonalizado: infos.nome_personalizado })
    this.setState({ selectedResiduo: infos.residuo_id})
    this.setState({ idMaterial: infos.id})
    this.setState({ residuoNome: infos.material})
    
  }

  renderTbody() {
    return (
      <tbody>
         {this.state.infoTable != '' ? this.state.infoTable.map(({id_equipamento_edit,id, client_id, cliente, area_id, area, data_instalacao, equipamento, fabricante, modelo}) => {       
           const infoSensor = {
              id_equipamento_edit,
              id,
              client_id,
              cliente,
              area_id,
              data_instalacao,
              equipamento,
              fabricante,
              modelo,
              area,
           }

           return (
              <tr key={id}>
                  {/* <td>{id_equipamento_edit}</td> */}
                  <td onClick={() => this.selectSensor(infoSensor)}>{cliente}</td>
                  <td onClick={() => this.selectSensor(infoSensor)}>{area}</td>
                  <td onClick={() => this.selectSensor(infoSensor)}>{data_instalacao}</td>
                  <td className="d-flex justify-content-space-between">
                    <span onClick={() => this.selectSensor(infoSensor)}>{equipamento}</span> 
                    <span onClick={() => this.deleteHistoric(id)}>x</span>
                  </td>
              </tr>
            )
          }) : ''}
      </tbody> 
    )
  }

  handleSelectedTipo = event => {
    const sensor = event.target.value;
    this.setState({ selectedTipo: sensor });
  }

  handleSelectedArea = event => {
    const sensor = event.target.value;

    // Recupera o texto da opção selecionado
    var index = event.nativeEvent.target.selectedIndex;
    const area_nome = event.nativeEvent.target[index].text

    this.setState({ selectedArea: sensor });
    this.setState({ areaNome: area_nome });
  }

  handleSelectedMaterial = event => {
    const residuos = event.target.value;

    // Recupera o texto da opção selecionado
    var index = event.nativeEvent.target.selectedIndex;
    const residuo_nome = event.nativeEvent.target[index].text

    this.setState({ selectedResiduo: residuos });
    this.setState({ residuoNome: residuo_nome });
  }

  handleIDSensorChange = event => {
    this.setState({ idSensor: event.target.value });
  }
  handleSerieChange = event => {
    this.setState({ nSerie: event.target.value });
  }
  handleModeloChange = event => {
    this.setState({ modelo: event.target.value });
  }
  handleFabricanteChange = event => {
    this.setState({ fabricante: event.target.value });
  }
  handleAtivacaoChange = event => {
    this.setState({ ativacao: event.target.value });
  }


  handleSelectedCliente = async (id_select, id_ger, nome_client) => {

    // const cliente_nome = event.target.options[event.target.selectedIndex].text
    // const idValue = event.target.value;
    // const id_plataformaverde = event.target.options[event.target.selectedIndex].dataset.plataformaverde;

    const cliente_nome = nome_client;
    const idValue = id_select;
    const id_plataformaverde = id_ger;

    this.setState({idClientCobotics: idValue})
    this.setState({nomeCliente: cliente_nome})
    
    await this.setState({selectedOption: idValue});

    // await dataSelecionada.map((data) => {

    //   this.setState({geradorPV: data.value})
    //   this.setState({idClientCobotics: data.id})
    //   this.setState({nomeCliente: data.label})
    
    // })      

    

    axios.get(`${process.env.REACT_APP_PV_BASE_URL}/api/v1/cobotics/listaArea?id_gerador=${id_plataformaverde}`)
    .then(resp => {
      this.setState({area : resp.data});
    })

    axios.get(`${process.env.REACT_APP_PV_BASE_URL}/api/v1/cobotics/listaResiduos?id_gerador=${id_plataformaverde}`)
    .then(resp => {
      this.setState({residuoPv : resp.data});
    })

    // this.state.clientArray.push(this.state.idClientCobotics);
    // let multVal = event.target.value;
    // let pegaVal = this.state.selectedCliente
    // let gerador = event.target.dataset.ger;

    // pegaVal.indexOf(multVal) === -1
    //   ? pegaVal.push(multVal)
    //   : pegaVal.length === 1
    //     ? (pegaVal = [])
    //     : pegaVal.splice(pegaVal.indexOf(multVal), 1)

  }

  // atualização dos campos de Equipamentos
  handleEquipamentoChange = event => {
    this.setState({ equipamento: event.target.value });
  }
  handleEqpFabricanteChange = event => {
    this.setState({ fabricEqp: event.target.value });
  }
  handleNomePersonalizadoChange = event => {
    this.setState({ nomePersonalizado: event.target.value });
  }
  handleEqpModeloChange = event => {
    this.setState({ modeloEqp: event.target.value });
  }
  /** Cria campo para trazer o id do equipamento cadastrado no banco */
  handleEqpIdEdit = event => {
    this.setState({ id_equipamento_edit: event.target.value })
  }
  handleEqpSerieChange = event => {
    this.setState({ serieEqp: event.target.value });
  }
  handleEqpGsmChange = event => {
    this.setState({ gsmEqp: event.target.value });
  }
  handleEqpLacreChange = event => {
    this.setState({ lacreEqp: event.target.value });
  }
  handleEqpAtivacaoChange = event => {
    this.setState({ ativacaoEqp: event.target.value });
  }
  handleEqpRemocaoChange = event => {
    this.setState({ remocaoEqp: event.target.value });
  }

  handleClick = async event => {
console.log('chama clique');    
    const populaGrid = {
      /** mais um parametro quando for edit */
      "id_equipamento_edit": this.state.id_equipamento_edit,
      "id": this.state.idClientCobotics,
      "id_client": this.state.selectedOption,
      "cliente": this.state.nomeCliente,
      "id_area": this.state.selectedArea,
      "area": this.state.areaNome,
      "data_instalacao": this.state.ativacaoEqp,
      "equipamento": this.state.equipamento,
      "fabricante": this.state.fabricEqp,
      "modelo": this.state.modeloEqp,
      "status": "incluso"
    }

    if(this.state.infoTable.length > 0) {
console.log('if ' + this.state.id_equipamento_edit)
      const newInfoGrid = this.state.infoTable.filter(item => item.id !== this.state.idEquip);
      
      await this.setState({ 
        infoTable: [        
          ...newInfoGrid,         
          populaGrid
        ]
      })

    } else {
console.log('else ' + this.state.id_equipamento_edit)
      await this.setState({ 
        infoTable: [        
          ...this.state.infoTable,
          populaGrid
        ]
      })  
        
    }    
   
   this.setState({equipamento: ''});
   this.setState({selectedOption: ''});
  }

  handleSubmit = async event => {
    event.preventDefault();
console.log('chama ao clicar no botão salvar');
    /** novo array para pegar id equipamento edit */
    const cria_array_idEquipamentoEdit = [];
    const cria_arra_idClient = [];
    const cria_array_idArea = [];
    const cria_array_nmArea = [];
    const cria_array_fabricaEqp = [];
    const cria_array_nomeEqp = [];
    const cria_array_modeloEqp = [];

    /** Material */
    const cria_array_materialPV = [];
    const cria_arrayNmMaterial = [];
    const cria_arrayNmPersonalizado = [];
    
    /** Operador */
    const cria_arrayNmOperador = [];
    const cria_arrayCracha = [];

    await this.state.infoTable.map(inc => {
      cria_array_idEquipamentoEdit.push(inc.id_equipamento_edit)
      cria_arra_idClient.push(inc.id)
      cria_array_idArea.push(inc.id_area)
      cria_array_nmArea.push(inc.area)
      cria_array_nomeEqp.push(inc.equipamento)
      cria_array_fabricaEqp.push(inc.fabricante)      
      cria_array_modeloEqp.push(inc.modelo)
    })

    await this.state.infoMaterialTable.map(mat => {
      cria_array_materialPV.push(mat.id)
      cria_arrayNmMaterial.push(mat.material)
      cria_arrayNmPersonalizado.push(mat.nome_personalizado)
    })

    await this.state.infoOperadorTable.map(op => {      
      cria_arrayNmOperador.push(op.operador)
      cria_arrayCracha.push(op.cracha)
    })
  
    this.setState({id_equipamento_edit: cria_array_idEquipamentoEdit})
    this.setState({clientArray: cria_arra_idClient})
    this.setState({selectedCliente: cria_arra_idClient})
    this.setState({selectedArea: cria_array_idArea})
    this.setState({arrayNmArea: cria_array_nmArea})
    this.setState({arraynomeEqp: cria_array_nomeEqp})
    this.setState({arrayfabricaEqp: cria_array_fabricaEqp})
    this.setState({arraymodeloEqp: cria_array_modeloEqp}) 
    
    this.setState({arrayMaterialPV: cria_array_materialPV})
    this.setState({arrayNmMaterial: cria_arrayNmMaterial})
    this.setState({arrayNmPersonalizado: cria_arrayNmPersonalizado})

    this.setState({arrayOperador: cria_arrayNmOperador})
    this.setState({arrayCracha: cria_arrayCracha})

    const sensor = {
      id_equipamento_banco: this.state.id_equipamento_edit,
      cliente_id: this.state.selectedCliente,
      tipo_sensor_id: this.state.selectedTipo,
      id_sensor: this.state.idSensor,
      numero_serie_sensor: this.state.nSerie,
      modelo_sensor: this.state.modelo,
      fabricante_sensor: this.state.fabricante,
      numero_gsm: this.state.gsmEqp,

      cliente_id: this.state.clientArray,      
      area_id: this.state.selectedArea,
      area_nome: this.state.arrayNmArea,
      equip_nome: this.state.arraynomeEqp,
      equip_fabricante: this.state.arrayfabricaEqp,      
      equip_modelo: this.state.arraymodeloEqp,

      data_ativacao: this.state.ativacaoEqp,
      data_remocao: this.state.remocaoEqp,
      dt_ativacao: this.state.ativacao,
    
      material_id: this.state.arrayMaterialPV,
      material_nome: this.state.arrayNmMaterial,
      nome_cobotics: this.state.arrayNmPersonalizado,

      operador_nome: this.state.arrayOperador,
      cracha: this.state.arrayCracha

    };

    // const material = {
      
    //   material_id: this.state.arrayMaterialPV,
    //   material_nome: this.state.arrayNmMaterial,
    //   nome_cobotics: this.state.arrayNmPersonalizado

    // };

    const equipamento = {
      // nome: this.state.equipamento,
      // fabricante: this.state.fabricEqp,
      // modelo: this.state.modeloEqp,
      // numero_serie: this.state.serieEqp,
      // numero_gsm: this.state.gsmEqp,
      // lacre: this.state.lacreEqp
      // data_ativacao: this.state.ativacaoEqp,
      // data_remocao: this.state.remocaoEqp
    };

    

    // const getDataTable = () => {
      

    //   const populaGrid = {        
    //     "cliente": this.state.nomeCliente,
    //     "area": this.state.areaNome,        
    //     "data_instalacao": this.state.ativacaoEqp,
    //     "equipamento": this.state.equipamento,
    //     // "cidade_id": 'Santos',
    //     // "sensores_vinculados": '1',
    //     "status": "incluso"

    //   }
      
    //  await this.setState({ infoTable: [
    //   ...this.state.infoTable, populaGrid
    //  ]     
    // })


    // arrayInfoTable.push(populaGrid)

    // this.setState({infoTable: arrayInfoTable});
      

    //   }
     
    // getDataTable();
    

    // if (this.state.sensorId) {
    //   axios.put(`${process.env.REACT_APP_IOT_BASE_URL}/sensor/edit/${this.state.sensorId}`, { sensor, equipamento })
    //   .then(resp => {
    //     alert(resp.data);
    //   }).catch((error) => {
    //       console.log('Whoops! Houve um erro.', error.message || error)
    //       alert('Houve um erro ao editar o Sensor');
    //   });
    // } else {
    axios.post(`${process.env.REACT_APP_IOT_BASE_URL}/sensor/save`, { sensor, equipamento })
    .then(resp => {
        
        this.setState({ modalVisible: true })        
        this.setState({ modalTitle: 'Concluído' })
        this.setState({ modalDescription: 'Sensor cadastrado com successo' })
        
        // document.getElementById("formSensor").reset();
      }).catch((error) => {
        console.log('Whoops! Houve um erro.', error.message || error)
        // alert('Houve um erro ao salvar o Sensor');
      });
    }
  // }

  deleteHistoric(id) {
    const newInfoTable = this.state.infoTable.filter((info) => info.id !== id);
    this.setState({infoTable: newInfoTable})
    // axios.post(`${process.env.REACT_APP_IOT_BASE_URL}/sensor/save`, { sensor, equipamento })
    //   .then(response => response.status == 'OK' ? this.setState({infoTable: newInfoTable}) : null)
    //   .catch(error => console.log('Whoops! Houve um erro.', error.message || error));
  }
  
  async addMaterial() { 
    
    const newMaterial = {
        id: this.state.idMaterial,
        residuo_id: this.state.selectedResiduo,
        material: this.state.residuoNome,
        nome_personalizado: this.state.nomePersonalizado
    }
    let newInfo = [];

    if(this.state.infoMaterialTable.length > 0 && this.state.idSensor != '') {      
      if (newMaterial.id) {
        const newInfoGrid = this.state.infoMaterialTable.filter(item => item.id !== newMaterial.id);
        newInfo = [...newInfoGrid, newMaterial]
        const orderNewInfo = newInfo.sort((a, b) => {
          return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
        })

        console.log(orderNewInfo)
        await this.setState({ 
          infoMaterialTable: orderNewInfo
        })  
      } else {
        console.log('adiciona um novo')
        
        await this.setState({ 
          infoMaterialTable: [...this.state.infoMaterialTable, newMaterial]
        })  
        console.log(this.state.infoMaterialTable)
      }
        
      
    } else {
      await this.setState({ 
        infoMaterialTable: [        
          ...this.state.infoMaterialTable,
          newMaterial
        ]
      })            
    } 
      
      this.setState({ selectedResiduo: 0});
      this.setState({ idMaterial: ''});
      this.setState({ nomePersonalizado: ''});
  }


  deleteMaterial(id) {
    const newMaterialTable = this.state.infoMaterialTable.filter((info) => info.id !== id);
    this.setState({infoMaterialTable: newMaterialTable})
  } 

  renderTableMaterial() {
    return (
      <tbody>
          {this.state.infoMaterialTable != '' ? this.state.infoMaterialTable.map(({
            id,
            residuo_id,
            nome_personalizado, 
            material
          }, indice) => {               
            const infoMaterial = {
              id,
              residuo_id,
              nome_personalizado,
              material
            } 

            return (
              <tr key={indice}>     
                  <td onClick={() => this.selectMaterial(infoMaterial)}>{indice + 1}</td>
                  <td onClick={() => this.selectMaterial(infoMaterial)}>{material}</td>
                  <td className="d-flex justify-content-space-between">
                    {nome_personalizado}
                    <span onClick={() => this.deleteMaterial(id)}>x</span>
                  </td>
              </tr>
            )
          }) : ''}
      </tbody> 
    )
  }

  deleteOperador(idCracha) {
    const newOperadorTable = this.state.infoOperadorTable.filter((info) => info.cracha !== idCracha);
    this.setState({infoOperadorTable: newOperadorTable})
  } 

  async addOperador() {
    const newOperador = {
        operador: this.state.nomeOperador,
        cracha: this.state.idCracha
    }

    if(this.state.infoOperadorTable.length > 0) {
      const newInfoGrid = this.state.infoOperadorTable.filter(item => item.cracha !== this.state.cracha);
      
      await this.setState({ 
        infoOperadorTable: [        
          ...newInfoGrid,         
          newOperador
        ]
      })

    } else {
      await this.setState({ 
        infoOperadorTable: [        
          ...this.state.infoOperadorTable,
          newOperador
        ]
      })            
    }    
  }

  renderTableOperador() {
    return (
      <tbody>
          {this.state.infoOperadorTable != '' ? this.state.infoOperadorTable.map(({
            operador, 
            cracha
          }, indice) => {     
            return (
              <tr key={indice}>
                  <td>{indice + 1}. {operador}</td>
                  <td>{cracha}</td>                    
                  <td>
                    <span onClick={() => this.deleteOperador(cracha)}>x</span>
                  </td> 
              </tr>
            )
          }) : ''}
      </tbody> 
    )
  }

  render() {
    return (
        <section className="content">
          <Header primaryTitle={'Sensores'} secundaryTitle={'Cadastro de Sensores'}/>
          <div className="d-flex">
            <SideMenu />
              <div className="container">
              <TitlePrimary title="Cadastro de Sensor" icon={iconTitle} className="border-icon"/>
              <form onSubmit={this.handleSubmit}>
                <div className="box-default">
                <TitleSecundary title="Dados do Sensor"/>
                  <div className="row">
                    <div className="form-control col-md-2">
                      <label htmlFor="tipo">Tipo de Sensor</label>
                        <select
                          name="tipo"
                          id="tipo"
                          value={this.state.selectedTipo}
                          onChange={this.handleSelectedTipo}
                          // ref={register}
                        >
                        <option value="" >Selecione um Sensor</option>
                          {this.state.tipoSensores}
                          {/* {this.state.tipoSensores.map(tp => (
                          <option key={tp} value={tp}>{tp} </option>
                          ))} */}
                    </select>
                  </div>
                  <div className="form-control col-md-5">
                    <label> ID do Sensor:</label>
                    <input type="text" name="id_sensor" value={this.state.idSensor} onChange={this.handleIDSensorChange} />
                  </div>
                  <div className="form-control col-md-5">
                    <label> Número de Série:</label>
                    <input type="text" name="numero_serie" value={this.state.nSerie} onChange={this.handleSerieChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="form-control col-md-4">
                    <label>Modelo do sensor:</label>
                    <input type="text" name="modelo" value={this.state.modelo} onChange={this.handleModeloChange} />
                  </div>
                  <div className="form-control col-md-4">
                    <label>Fabricante:</label>
                    <input type="text" name="fabricante" value={this.state.fabricante} onChange={this.handleFabricanteChange} />
                  </div>
                  <div className="form-control col-md-4">
                    <label>Número GSM:</label>
                    <input type="text" name="eqp_gsm" value={this.state.gsmEqp} placeholder="Número GSM" onChange={this.handleEqpGsmChange} />
                  </div>
                </div>

                <div className="row">
                  {/* <div className="form-control col-md-3">
                    <label>Número de Série:</label>
                    <input type="text" name="eqp_serie" value={this.state.serieEqp} placeholder="Número de série" onChange={this.handleEqpSerieChange} />
                  </div> */}
                  <div className="form-control col-md-4">
                    <label>Data de Ativação:</label>
                    <InputMask mask="99/99/9999" placeholder="dd/mm/aaaa" type="text" name="ativacao" value={this.state.ativacao} onChange={this.handleAtivacaoChange} />
                  </div>
                  {/* <div className="form-control col-md-3">
                    <label>Lacre:</label>
                    <input type="text" name="eqp_lacre" value={this.state.lacreEqp} placeholder="Lacre" onChange={this.handleEqpLacreChange} />
                  </div> */}
                  <div className="form-control col-md-4">
                    <label>Data de Instalação:</label>
                    <InputMask mask="99/99/9999" placeholder="dd/mm/aaaa" type="text" name="eqp_ativacao" value={this.state.ativacaoEqp}  onChange={this.handleEqpAtivacaoChange} />
                  </div>
                  <div className="form-control col-md-4">
                    <label>Data da Remoção:</label>
                    <InputMask mask="99/99/9999" placeholder="dd/mm/aaaa" type="text" name="eqp_remocao" value={this.state.remocaoEqp} onChange={this.handleEqpRemocaoChange} />
                  </div>
                </div>

              </div>
              <div className="box-default">
                <TitleSecundary title="Informações do Cliente" />
                <div className="row">
                  <div className="form-control col-md-6">
                    <label>Cliente:</label>
                    
                    <select
                      name="client"
                      id="client"
                      value={this.state.selectedOption}
                      onChange={ (event) => this.handleSelectedCliente(
                        event.target.value, 
                        event.target.options[event.target.selectedIndex].dataset.plataformaverde,
                        event.target.options[event.target.selectedIndex].text )}
                    >
                      <option value="">Escolha um cliente</option>                    
                      {this.state.options.map(area => (
                        <option key={area.id} value={area.id} data-plataformaverde={area.value}>{area.label} </option>
                      ))}
                    </select>
            
                  </div>
                  <div className="form-control col-md-6">
                    <label>Área:</label>
                    <select
                      name="area_id"
                      id="area_id"
                      value={this.state.selectedArea}
                      onChange={this.handleSelectedArea}
                    >
                      <option value="" >Selecione uma área da Pv</option>                    
                      {this.state.area.map(area => (
                        <option key={area.id} value={area.id}>{area.nome} </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/** Dados do Equipamento */}
                <div className="row">
                  <div className="form-control col-md-4">
                    <label>Equipamento:</label>
                    <input type="text" name="equipamento" value={this.state.equipamento} placeholder="Equipamento" onChange={this.handleEquipamentoChange} />
                  </div>
                  <div className="form-control col-md-4">
                    <label>Fabricante:</label>
                    <input type="text" name="eqp_fabricante" value={this.state.fabricEqp} placeholder="Fabricante" onChange={this.handleEqpFabricanteChange} />
                  </div>
                  <div className="form-control col-md-4">
                    <label>Modelo:</label>
                    <input type="text" name="eqp_modelo" value={this.state.modeloEqp} placeholder="Modelo" onChange={this.handleEqpModeloChange} />
                  </div>                  
                  <input type="hidden" name="id_eqp_edit" value={this.state.id_equipamento_edit} placeholder="id edit eqp" onChange={this.handleEqpIdEdit} />                  
                </div>
                
                <a onClick={this.handleClick} className="btn-action btn-confirm">
                  <div className="w-100 text-center">
                    <p>Incluir</p>
                  </div>
                  <span><img src={iconSave} /></span>
                </a>
              </div>              
              {/* Histórico de instalação */}
                <div className="box-default">
                  <TitleSecundary title="Histórico de instalação" />
                  <table className="table-default">
                    <thead>
                        <tr>
                            {this.state.headerTable.map((item, index) => (
                                <th key={index}>
                                   <span> {item.name}</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {this.renderTbody()}
                  </table>
                </div>
                {this.state.selectedTipo == 1 &&   
                <>                               
                  <div className="box-default">
                    <TitleSecundary title="Material"/>
                    <div className="row">
                      <div className="form-control col-md-5">
                        <label htmlFor="tipo">Material</label>
                        <select
                          name="materialPv"
                          id="materialPV"
                          value={this.state.selectedResiduo}
                          onChange={this.handleSelectedMaterial}
                        >
                        <option value="" >Selecione o Material</option>                    
                        {this.state.residuoPv.map(residuos => (
                          <option key={residuos.id} value={residuos.id}>{residuos.residuo} </option>
                        ))}
                      </select>
                      </div>
                      <div className="form-control col-md-5">
                        <label>Nome Personalizado:</label>
                        <input type="text" name="matrialCB" value={this.state.nomePersonalizado} placeholder="Nome Personalizado" onChange={this.handleNomePersonalizadoChange} />
                      </div>                 
                      <input type="hidden" value={this.state.idMaterial} onChange={(e) => this.setState({idMaterial: e.target.value})} /> 
                      <a onClick={() => this.addMaterial()} className="btn-lateral btn-confirm">
                        <div className="w-100 text-center">
                          <p>Incluir</p>
                        </div>
                        <span><img src={iconSave} /></span>
                      </a>
                    </div>
                    <div className="box-default">                                      
                      <table className="table-default">
                        <thead>
                          <tr>
                            {this.state.headerTableMaterial.map((item, index) => (
                              <th key={index}>
                                <span> {item.name}</span>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        {this.renderTableMaterial()}
                      </table>
                    </div>
                  </div>
                  
                  <div className="box-default">
                    <TitleSecundary title="Operador"/>
                    <div className="row">
                      <div className="form-control col-md-5">
                        <label htmlFor="tipo">Operador</label>
                        <input type="text" name="operador" value={this.state.nomeOperador} placeholder="Nome Operador" onChange={(e) => this.setState({ nomeOperador: e.target.value})} />  
                      </div>
                      <div className="form-control col-md-5">
                        <label>ID do Crachá:</label>
                        <input type="text" name="matrialCB" value={this.state.idCracha} placeholder="ID do crachá" 
                        onChange={(e) => this.setState({ idCracha: e.target.value})} />
                      </div>
                      {/* <div className="form-control col-md-2">
                        <button type="button" onClick={() => this.addOperador()}>Incluir</button>
                      </div>                     */}
                      <a onClick={() => this.addOperador()} className="btn-lateral btn-confirm">
                        <div className="w-100 text-center">
                          <p>Incluir</p>
                        </div>
                        <span><img src={iconSave} /></span>
                      </a>
                    </div>
                    <table className="table-default">
                      <thead>
                      <tr>
                        {this.state.headerTableOperador.map((item, index) => (
                          <th key={index}>
                            <span> {item.name}</span>
                          </th>
                        ))}
                      </tr>
                      </thead>
                        {this.renderTableOperador()}
                      </table>
                  </div> 
                </>     
                }
                
                  <button type="submit" className="btn-action btn-confirm">
                    <div className="w-100 text-center">
                      <p>salvar</p>
                    </div>
                    <span><img src={iconSave} /></span>
                  </button>
                
            </form>            
          </div>          
        </div>

        {this.state.modalVisible ? 
        <ModalWarning 
        title={this.state.modalTitle} 
        close={() => this.setState({modalVisible: false})}
        description={this.state.modalDescription}/> 
        : null}

        {/* <ModalConcluded /> */}
      </section>
    )
  }
}