import React, { Component } from 'react';
import { Link }	from	'react-router-dom';
import Forms from '../../components/Forms/Forms';
import logo from '../../logo.svg';

class Login extends Component {
    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <p>
                        seja bem-vindo Cobotics!
                    </p>

                    <div>
                    <Forms />
                    </div>

                    {/* <Link to="/cliente">
                    <button>
                        <p>
                            Lista Cliente
                        </p>
                    </button>
                    </Link>
                    <Link to="/cadCliente">
                    <button>
                        <p>
                            Cad Cliente
                        </p>
                    </button>
                    </Link> */}

                </header>
            </div>
        )
    }
}

export default Login;
