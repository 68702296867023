import React from 'react';
import '../modal.css';

const ModalWarning = ({title, description, close}) => {
    const handleRedirect = event => {
        window.location.href = `/cliente`;
    }
    return (
        <div className="modal">
            <div className="modal-box">
                <div className="modal-header">
                    <h3>{title}</h3>
                    <div onClick={handleRedirect}>
                        <span>x</span>
                    </div>
                </div>
                <div className="modal-body">
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default ModalWarning;