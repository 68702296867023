import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

const Button = (props) => {
    return (
        <Link to={props.link} className="btn-emitir">
            <FaPlus />
            Novo
        </Link>
    )    
}

export default Button;