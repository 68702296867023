import '../titles.css';

const TitleSecundary = (props) => {
    const infoDefault = (props.info === undefined) ? 'O texto explicativo do item deve aparecer somente se o usuário clicar no ícone. Para fechar clique no X.' : props.info;

    return (
        <div className="title-secundary">
            <h2>{props.title}</h2>
            <div className="i-icon">
                <span>i</span>
                <div className="box-info">
                    <span>X</span>
                    <p>{infoDefault}</p>
                </div>
            </div>
        </div>
    )
}

export default TitleSecundary;