import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';

import Login from '../containers/Login/Login';
import Cliente from '../containers/Clientes/Cliente';
import Sensores from '../containers/Sensores/Sensores';
import CadCliente from '../containers/Clientes/CadClientes/CadCliente';
import CadSensor from '../containers/Sensores/CadSensores/CadSensor';
import Registro from '../containers/Registros/Balancas/ListBalancas';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/cliente' component={Cliente} />
            <Route exact path='/cadCliente' component={CadCliente} />
            <Route exact path='/sensores' component={Sensores} />
            <Route exact path='/cadSensor' component={CadSensor} />
            <Route exact path='/cadSensor/:id' component={CadSensor} />
            <Route exact path='/cadCliente/:id' component={CadCliente} />
            <Route exact path='/listBalancas' component={Registro} />
        </Switch>
    </BrowserRouter>
)

export default Routes;
