import { NavLink }	from	'react-router-dom';
import './side-menu.css'
import iconCadastrar from '../../assets/images/icons/icon_cadastrar_cliente.svg';
import iconCalc from '../../assets/images/icons/icn-cobotics-registro.svg';
import iconBateria from '../../assets/images/icons/icn-cobotics-status.svg';
import iconSensor from '../../assets/images/icons/icn-cobotics-cadastro-sensores.svg';
import iconDashboard from '../../assets/images/icons/icn-cobotics-cadastro-clientes.svg';
// import iconBateria from '../../assets/images/icons/icon_bateria.svg';
// import iconBateria from '../../assets/images/icons/icon_bateria.svg';

const SideMenu = () => (
    <div className="side-menu">
        <ul>
            <li>
                {/* <NavLink activeClassName="active" to="/cliente"> */}
                <a >
                    <img src={iconDashboard} title="Lista de Clientes"/>
                    <p>Dashboard</p>
                </a>
                {/* </NavLink> */}
            </li>
            <li>
                <NavLink activeClassName="active" to="/cliente">
                    <img src={iconCadastrar} title="Cadastro de Cliente"/>
                    <p>Lista CLiente</p>
                </NavLink>
            </li>
            <li>
                <NavLink activeClassName="active" to="/sensores" window="sensores">
                    <img src={iconSensor} />
                    <p>Sensores</p>
                </NavLink>
            </li>
            <li>
                <a >
                <img src={iconBateria} />
                <p>Bateria</p>
                </a>
            </li>
            <li>
                <NavLink activeClassName="active" to="/listBalancas" window="registro">
                    <img src={iconCalc} />
                </NavLink>
            </li>
        </ul>
    </div>
);

export default SideMenu;