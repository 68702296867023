import iconTitle from '../../../assets/images/icons/icon-cadastro-cliente.svg';
import '../titles.css';

const TitlePrimary = (props) => {
    const iconDefault = (props.icon === undefined) ? iconTitle : props.icon;

    return (
        <div className="title-page">
            <img src={iconDefault} className="icon-title"/>
            <h3>{props.title}</h3>
        </div>
    )
}

export default TitlePrimary;
