import React, { useEffect, useState, useMemo } from "react";
import TableHeader from "./Header";
import Pagination from "./Pagination";
import Search from "./Search";
import { useHistory } from 'react-router-dom';
import ModalWarning from '../../components/Modals/ModalWarning/ModalWarning';
import './table.css'
import Button from "./Button";

const Table = ({ data, modal, click, linkButton }) => {  
    console.log('chegou o body' + data.body)
  const link = window.location.href.split('/')[window.location.href.split('/').length - 1];
  const [tableList, setTableList] = useState(data.body);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsShow, setItemsShow] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [itemPerPage, setItemPerPage] = useState(10);
  const [isVisibleWarning, setIsVisibleWarning] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [justify, setJustify] = useState([]);
  
  const history = useHistory();
  let itemsTable = document.querySelectorAll('.table-default tbody tr').length * currentPage;

  const listData = useMemo(() => {
    let listClients = tableList;    

    if (search) {
        if (link === 'clientes') {
            listClients = listClients.filter(
                client =>
                    client.razao_social.toLowerCase().includes(search.toLowerCase()) ||
                    client.nome_fantasia.toLowerCase().includes(search.toLowerCase()) ||
                    client.estado_id.toLowerCase().includes(search.toLowerCase()) ||
                    client.cidade_id.toLowerCase().includes(search.toLowerCase())
            );
        }
        
        if (link === 'sensores') {
            listClients = listClients.filter(
                client =>
                    client.razao_social.toLowerCase().includes(search.toLowerCase()) ||
                    client.nome_fantasia.toLowerCase().includes(search.toLowerCase())
            );
        }
    }

    setTotalItems(listClients.length);
    setItemsShow(itemsTable)

    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        listClients = listClients.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    return listClients.slice(
        (currentPage - 1) * itemPerPage,
        (currentPage - 1) * itemPerPage + itemPerPage
    );
}, [tableList, currentPage, search, sorting, itemPerPage]);

  const changeItemsPerPage = (items) => {
      setItemPerPage(items)
  }

  const handleNavigate = (id) => {
      history.push({
          pathname: `${linkButton}/` + id,
          id: id
      });
  }  

  const tbodyInfo = () => {
      if (link === 'cliente') {
        return listData.map(item => {
            // const isCancel = item.status === 'cancelado'  ? 'cancel' : '';
            const isCancel = item.inativo === 1 ? 'cancel' : '';
            const stt = item.inativo === 1 ? 'Inativo' : 'Ativo';
        
            return (
                <tr  key={item.id} className={isCancel}>
                    <td onClick={() => handleNavigate(item.id)}>
                        {item.razao_social}
                    </td>
                    <td onClick={() => handleNavigate(item.id)}>{item.nome_fantasia}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.estado_id}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.cidade_id}</td>
                    <td onClick={() => handleNavigate(item.id)}>0</td>
                    <td className="position-relative">{stt}
                        <ul className="list-icons">
                            {item.inativo === 1 ? <li className="icon-justify" onClick={() => modal(item.id, 'justify')}></li> : 
                            <li className="icon-delete" onClick={() => modal(item.id, 'delete')}></li>}
                        </ul>
                    </td>
                </tr>
            )
        })      
      } else if (link === 'sensores') {
        return listData.map(item => {
            const isCancel = item.status === 'cancelado'  ? 'cancel' : '';                                      
        
            return (
                <tr key={item.id} className={isCancel}>
                    <td onClick={() => handleNavigate(item.id)}>
                        {item.id_sensor}
                    </td>
                    <td onClick={() => handleNavigate(item.id)}>{item.nome}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.razao_social}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.modelo_sensor}</td>
                    <td className="position-relative">{item.status}
                        <ul className="list-icons">
                            {item.status === 'cancelado' ? <li className="icon-justify" onClick={() => click(item.id)}></li> : <li className="icon-delete" onClick={() => modal(item.id, 'delete')}></li>}
                        </ul>
                    </td>
                </tr>
            )
        })      
      } else if (link === 'cadSensor') {
        return listData.map(item => {
            const isCancel = item.status === 'cancelado'  ? 'cancel' : '';                                      
        
            return (
                <tr key={item.id} className={isCancel}>
                    <td onClick={() => handleNavigate(item.id)}>
                        {item.cliente}
                    </td>
                    <td onClick={() => handleNavigate(item.id)}>
                        {item.area}
                    </td>
                    <td onClick={() => handleNavigate(item.id)}>{item.data_instalacao}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.equipamento}</td>
                    {/* <td onClick={() => handleNavigate(item.id)}>{item.cidade_id}</td> */}
                    <td className="position-relative">{item.status}
                        <ul className="list-icons">
                            {item.status === 'cancelado' ? <li className="icon-justify" onClick={() => click(item.id)}></li> : <li className="icon-delete" onClick={() => modal(item.id, 'delete')}></li>}
                        </ul>
                    </td>
                </tr>
            )
        })      
      } else if (link === 'listBalancas') {
        return listData.map(item => {
            const isCancel = item.status === 'cancelado'  ? 'cancel' : '';                                      
        
            let ano = item.datetime.substr(0, 4);
            let mes = item.datetime.substr(5, 2);
            let dia = item.datetime.substr(8, 2);
            let hora = item.datetime.substr(10, 9);

            const dtFormata = dia + '/' + mes + '/' + ano + '' + hora;

            let ano1 = item.date_sensor.substr(0, 4);
            let mes1 = item.date_sensor.substr(5, 2);
            let dia1 = item.date_sensor.substr(8, 2);
            let hora1 = item.date_sensor.substr(10, 9);

            const dtSensor = dia1 + '/' + mes1 + '/' + ano1 + '' + hora1;


            return (
                <tr key={item.id} className={isCancel}>
                    <td onClick={() => handleNavigate(item.id)}>{item.id_equipamento}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.bruto}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.liquido}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.tara}</td>
                    <td onClick={() => handleNavigate(item.id)}>{dtFormata}</td>
                    <td onClick={() => handleNavigate(item.id)}>{dtSensor}</td>
                    <td className="position-relative">{item.status}
                        <ul className="list-icons">
                            {item.status === 'cancelado' ? <li className="icon-justify" onClick={() => click(item.id)}></li> : <li className="icon-delete" onClick={() => modal(item.id, 'delete')}></li>}
                        </ul>
                    </td>
                </tr>
            )
        })      
      } else {
        return listData.map(item => {
            const isCancel = item.status === 'cancelado'  ? 'cancel' : '';                                      
        
            return (
                <tr key={item.id} className={isCancel}>
                    <td onClick={() => handleNavigate(item.id)}>{item.nome_cliente}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.area}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.data_ativacao}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.data_remocao}</td>
                    <td onClick={() => handleNavigate(item.id)}>{item.nome_equipamento}</td>
                    <td className="position-relative">{item.status}
                        <ul className="list-icons">
                            {item.status === 'cancelado' ? <li className="icon-justify" onClick={() => click(item.id)}></li> : <li className="icon-delete" onClick={() => modal(item.id, 'delete')}></li>}
                        </ul>
                    </td>
                </tr>
            )
        })      
      }
  }

  return (
    <>
        <div className="d-flex justify-content-space-between">  
            <div className="d-flex align-items-center">
                <select className="select-table" onChange={(e) => changeItemsPerPage(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
                ITEMS POR PÁGINA
            </div>                      
            <div className="d-flex align-items-center">
                <Search                    
                    onSearch={value => {
                        setSearch(value);
                        setCurrentPage(1);
                    }}
                />
                <Button link={linkButton}/>
            </div>            
        </div>
        <table className="table-default">
            <TableHeader
                headers={data.header}
                onSorting={(field, order) =>
                    setSorting({ field, order })
                }
            />
            <tbody>
                {tbodyInfo()}
            </tbody> 
        </table>
        <div className="d-flex justify-content-space-between">
            <p>MOSTRANDO DE 1 ATÉ {itemsShow} DE {totalItems}</p>        
            <div>
                <Pagination
                    total={totalItems}
                    itemsPerPage={itemPerPage}
                    currentPage={currentPage}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
            {isVisibleWarning ? 
                <ModalWarning 
                    title={title} 
                    close={() => setIsVisibleWarning(false)}
                    description={description} 
                /> 
            : null}    
        </div>
    </>
  )
}

export default Table;
