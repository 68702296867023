import Routes from '../src/routes';
import dotenv from  'dotenv';

function App() {
  return (
    <Routes />
  );
}

export default App;
