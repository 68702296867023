import './header.css';
import logoCobotics from '../../assets/images/logos/logo_cobotics.png';
import logoTop from '../../assets/images/logos/logo-pv-topo.png';
import SubHeader from '../SubHeader/SubHeader';

const Header = ({ primaryTitle, secundaryTitle }) => (
    <header className="area-header">
        <div className="header">
            <img src={logoCobotics} className="logo_cobotics"/>
            <div>

            </div>
            <h1 className="title-header">Cobotics - Administrativo</h1>
            <img src={logoTop} width="70" height="70"/>
        </div>
        <SubHeader primaryTitle={primaryTitle} secundaryTitle={secundaryTitle} />
    </header>
)

export default Header;