import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import TitleInput from '../../Titles/TitleInput/TitleInput';
import moment from 'moment';
import '../modal.css';
import '../../Table/table.css';

const ModalJustify = ({
        close,
        justify
    }) => {   
        console.log(justify)
    return (
        <>
        <div className="modal">
            <div className="modal-box modal-box-form">
                <div className="modal-header">
                    <h3>Justificativas  </h3>
                    <div onClick={() => close('justify')}>
                        <span>x</span>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="scroll-table">
                        <table className="table-modal">
                            <thead>
                                <tr>
                                    <td>Data</td>
                                    <td>Alteração</td>
                                    {/* <td>Usuário</td> */}
                                    <td>Motivo</td>
                                </tr>
                            </thead>
                            <tbody>                                
                                {justify.map((item) => {
                                    console.log(item);
                                    return ( 
                                        <tr key={item.id}>
                                            <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                                            <td>{item.tipo}</td>
                                            {/* <td>{item.tipo}</td> */}
                                            <td><strong>{item.justificativa.justificativa} </strong><br /> {item.descricao}</td>
                                        </tr>
                                    )                                    
                                })}                                
                            </tbody>
                        </table>
                    </div>
                </div>                
            </div>            
        </div>        
        </>
    )
}

export default ModalJustify;