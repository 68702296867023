import '../titles.css'
const TitleInput = ({name, info}) => (
    <div className="d-flex justify-content-between">
    <label>{name}</label>
    {/* <div className="i-icon">
        <span>i</span>
        <div className="box-info">
            <span>X</span>
            <p>{info}</p>
        </div>
    </div> */}
    </div>
)

export default TitleInput;