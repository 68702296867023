import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";

const Forms = () => {

    let history = useHistory();

    const [valueInput, setValue] = useState({});

    const changeValue = e => {
        //clono o meu objeto no state values
        const auxValues = { ...valueInput };
        //de qualquer forma iremos inserir no state value, senão limpa o input do usuário.
        auxValues[e.target.name] = e.target.value;
        //seta o novo objeto

        setValue(auxValues);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(valueInput.email);
        // console.log(valueInput.password);
        const config = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
            // "Authorization": "Bearer "+access_token
            }
        }

        //axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/?email=${email}&password=${password}`)
        axios.post(`${process.env.REACT_APP_IOT_BASE_URL}/login`,{
            email: valueInput.email,
            password: valueInput.password
        })
        .then(res => {
            console.log(res);
            console.log(res.data);

            localStorage.setItem('tokencobotics', res.data.token);

            history.push({
                pathname: '/cliente'
            });

        }).catch((error) => {
            console.log('Whoops! Houve um erro.', error.message || error)
        });

    }

    const [results, setResults] = useState(['']);

    return (
        <div>
            <div className="content">

                <form onSubmit={handleSubmit}>
                    <div className="forms">

                        <fieldset>
                            <legend>
                                <h2>Login</h2>
                            </legend>

                            <div className="forms__field">
                                <label htmlFor="email">email</label>
                                <input
                                    type="text"
                                    placeholder="Exemplo: email@dominio.com"
                                    name="email"
                                    id="email"
                                    onChange={changeValue}
                                    value={valueInput.email}
                                />
                            </div>

                            <div className="forms__field">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="text"
                                    placeholder="Ex: 123456578"
                                    name="password"
                                    id="password"
                                    onChange={changeValue}
                                    value={valueInput.password}
                                />
                            </div>

                        </fieldset>

                        <input type="submit" value="Enviar" />
                    </div>
                </form>
            </div>

        </div>
    );
}

export default Forms;