import './sub-header.css';

const Header = ({ primaryTitle, secundaryTitle}) => {
        return (
            <div className="sub-header d-flex">
                <div className="col-md-2"></div>
                <div className="col-md-10">
                    <p>Módulo Cobotics / <span>{
                        primaryTitle !== undefined ? primaryTitle : ''
                    }  
                    {
                        secundaryTitle !== undefined ? ' / ' + secundaryTitle : ''
                    }</span></p>
                </div>
            </div>
        )
    }    

export default Header;