import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Table from '../../components/Table/Table';
import Header from '../../layouts/Header/Header';
import iconTitle from '../../assets/images/icons/icon-sensores.svg';
import SideMenu from '../../layouts/SideMenu/SideMenu';
import TitlePrimary from '../../components/Titles/TitlePrimary/TitlePrimary';
import TitleSecundary from '../../components/Titles/TitleSecundary/TitleSecundary';
import ModalDelete from '../../components/Modals/ModalDelete/ModalDelete';
import ModalWarning from '../../components/Modals/ModalWarning/ModalWarning';
import ModalJustify from '../../components/Modals/ModalJustify/ModalJustify';
import './sensor.css';

const Sensores = () => {
  const [ infoTable, setInfoTable ] = useState([]);
  const [ isVisibleDelete, setIsVisibleDelete ] = useState(false);
  const [ isVisibleJustify, setIsVisibleJustify ] = useState(false);
  const [ idItem, setIdItem] = useState('');
  const [ count, setCount ] = useState(0);   
  const [ reason, setReason ] = useState();
  const [ justify, setJustify ] = useState([
    {
      id: 1,
            data:new Date(),
            alteracao: 'teste',
            usuario: 'teste',
            motivo: 'teste',
            descricao: 'teste'
    }
  ]);
  const [ isVisibleWarning, setIsVisibleWarning] = useState(false)
  const [ title, setTitle ] = useState('');
  const [ description, setDescription ] = useState('');    
  const access_token = localStorage.getItem('tokencobotics'); 
  
  const config = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Authorization": "Bearer "+access_token
    }
  }  

  useEffect(() => {
    const getDataTable = () => {
      const header = [
        { name: "Id do sensor", field: "id_sensor", sortable: true },
        { name: "Tipo de sensor", field: "tipo_sensor", sortable: true },
        { name: "cliente", field: "cliente", sortable: true },
        { name: "modelo", field: "modelo", sortable: true },
        { name: "status", field: "status", sortable: true }
      ];

      axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/sensor/listaSensores`, { headers: config.headers })
        .then(response => { 
console.log('oi', response.data)
          return response.data;        
        })
        .then(response => {
          setInfoTable({
            header,
            body: response,
          })        
        })
        .catch((error) => {
          setIsVisibleWarning(true);           
          setTitle('Erro');
          setDescription('Whoops! Houve um erro no envio.', error.message || error);
        }); 
    }
    getDataTable();
  }, []); 

  const visibleModal = (id, typeModal) => {
    setIdItem(id);

    if (typeModal === 'delete') {
      setIsVisibleDelete(!isVisibleDelete)
      setIsVisibleJustify(false);
    }
    
    if (typeModal === 'justify') {
      setIsVisibleJustify(!isVisibleJustify);
      setIsVisibleDelete(false)
    }
  }
    
  const renderTable = useMemo(() => {
    if (infoTable.header !== undefined) {
         return <Table 
          data={infoTable} 
          modal={visibleModal} 
          justify={justify}
          click={() => handleJustify(idItem)}
          linkButton={"/cadSensor"}
         />
    } 
  }, [ infoTable ]);

  const handleSubmit = () => {
    const data = {
        id: idItem,
        reason: reason,
        justify: justify
    }

    axios.post(`http://iotdev.plataformaverde.com.br/cliente/save`, { data })
        .then(res => {
          const newInfo = [];

          setIsVisibleDelete(false);
          setIsVisibleWarning(true);
          setTitle('Concluído');
          setDescription(`Prensa ${idItem} inativada`);          

          infoTable.body.filter((item) => item.id != idItem ? newInfo.push(item) : null);

          setInfoTable({
            header: infoTable.header,
            body: newInfo,
          })           

          setTimeout(() => {
            setIsVisibleWarning(false);
          }, 2000);
        }).catch((error) => {
            setIsVisibleDelete(false);
            setIsVisibleWarning(true);           
            setTitle('Erro');
            setDescription(error.message);
        });
  }

  const countCharacter = (char, value) => { 
    setJustify(value);
    setCount(char);
  }    

  const handleJustify = (id) => {
    // axios.get()
    //   .then((response) => {
    //     const teste = [
    //         {
    //             id: 1,
    //             data:new Date(),
    //             alteracao: 'teste',
    //             usuario: 'teste',
    //             motivo: 'teste',
    //             descricao: 'teste'
    //         }
    //     ]

    //     setJustify(teste)        
    //   }).catch(() => {
    //     visibleModal(id, 'justify');
    //   })
    //   .catch((error) => {
    //       setIsVisibleWarning(true);           
    //       setTitle('Erro');
    //       setDescription('Whoops! Houve um erro no envio.' + error.message || error);
    //   })
      
      const teste = [
        {
            id: 1,
            data:new Date(),
            alteracao: 'teste',
            usuario: 'teste',
            motivo: 'teste',
            descricao: 'teste'
        }
    ]

    setJustify(teste)

    visibleModal(idItem, 'justify');
  }

  return (
    <section className="content">
      <Header primaryTitle={'Sensores'}/>
      <div className="d-flex">
        <SideMenu />
        <div className="container w-100">
            <TitlePrimary title="Sensores" icon={iconTitle}/>
            <div className="box-default">
              <TitleSecundary title="Sensores Cadastrados"/>                    
              {renderTable}
            </div>
        </div>
      </div>
      {isVisibleDelete ? 
        <ModalDelete 
          close={() => setIsVisibleDelete(false)} 
          id={idItem} 
          click={handleSubmit}
          reason={reason}
          justify={justify}
          changeReason={(e) => setReason(e.target.value)}
          count={count}
          title={`Inativar Sensor - ${idItem}`}
          changeJustify={(e) => countCharacter(e.target.value.length, e.target.value)}
        /> 
        : null
      }

      {
        isVisibleJustify ? 
        <ModalJustify 
          close={() => setIsVisibleJustify(false)} 
          id={idItem} 
          click={() => handleJustify(idItem)}
          reason={reason}
          justify={justify}
          changeReason={(e) => setReason(e.target.value)}
          count={count}
          changeJustify={(e) => countCharacter(e.target.value.length, e.target.value)}
        /> 
        : null
      }

      {isVisibleWarning ? 
        <ModalWarning 
          close={() => setIsVisibleWarning(false)}
          title={title} 
          description={description} 
        /> 
      : null}            
    </section>
  );
}

export default Sensores;