import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { FaCheck } from 'react-icons/fa';
import TitleInput from '../../Titles/TitleInput/TitleInput';
import '../modal.css';
import ModalWarning from '../ModalWarning/ModalWarning';

const ModalDelete = ({
        id, 
        close,
        changeReason,
        count,
        changeJustify,
        click,
        title

    }) => {

        const [justify, setJustify] = useState([]);
        const [selectedJ, setSelectedJ] = useState();
        const [desc, setDesc] = useState();
        const [ isVisibleWarning, setIsVisibleWarning] = useState(false);
        const [ titulo, setTitulo ] = useState('');
        const [ description, setDescription ] = useState('');    
        // let history = useHistory();

        function validate(value){
            const error = {};

            if(!value){

                error.erroDesc = 'Campo Justificativa necessario';
            }

            return error;
        };

        
        const [error, setError] = useState({});

        useEffect((prevState) => {
            
                axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/justificativa/show`).then(response => {
                    const ufInitials = response.data;

                    setJustify(ufInitials);
                
                })
                .catch((error) => {
                    
                }); 
                
          }, []);

          const handleSelectedJ = event => {
            const jus = event.target.value;
        
            setSelectedJ(jus);
          }

          const handleDesc = event => {
            event.preventDefault();
            const d = event.target.value;
            
            setDesc(d);
          }

          const handleInativa = event => {

            event.preventDefault();

            setError(validate(desc));
            
            const DataJustify = {
                id_justificativa: selectedJ,
                descricao: desc
            };

            if(!error.erroDesc){
                if(title == 'Ativar Cliente') {
                    axios.post(`${process.env.REACT_APP_IOT_BASE_URL}/cliente/ativarCliente/${id}`, { DataJustify })
                        .then(resp => {
                        
                        if(resp.data == 'ativado'){                            
                            setIsVisibleWarning(true);
                            setTitulo('Concluído');
                            setDescription('Cliente ativado com successo');
                            // window.location.href = '/cliente';
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    axios.post(`${process.env.REACT_APP_IOT_BASE_URL}/cliente/inativarCliente/${id}`, { DataJustify })
                    .then(res => {

                        if(res.data === 'inativado'){
                            setIsVisibleWarning(true);
                            setTitulo('Concluído');
                            setDescription('Cliente inativado com successo');
                            // window.location.href = '/cliente';
                        
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                }    
            }    
          }

    return (
        <>
        <div className="modal">
            <div className="modal-box modal-box-form">
                <div className="modal-header">
                    <h3>{title}</h3>
                    <div onClick={() => close('delete')}>
                        <span>x</span>
                    </div>
                </div>
                <div className="modal-body">
                    <p>Com essa ação você estará inativando este sensor dentro do sistema. Isso significa que ele não aparecerá mais em listagem  e não poderá ser mais selecionado para algumas outra ação. Caso queira reutilizá-lo, você poderá reativá-lo posteriormente.</p>
                    <form>                   
                        {/* <div className="form-control">
                            <TitleInput name="Motivo" info="texto default"/>
                            <select
                                name="justificativa"
                                id="justificativa"
                                value={selectedJ}
                                onChange={handleSelectedJ}
                            >
                            <option value="" >Selecione</option>
                            {justify.map(j => (
                                <option key={j.id} value={j.id}>{j.justificativa} </option>
                            ))}
                            </select>
                        </div>   */}
                        <div className="form-control">
                            <div className="d-flex justify-content-space-between">                                
                                <TitleInput name="Justificativa" info="texto default" />                                
                                <p>{count}/500 Caracteres</p>
                            </div>                                
                            <textarea onChange={handleDesc} value={desc}></textarea>
                        </div>
                        {error.erroDesc && <span className="error">{error.erroDesc}</span>}
                    </form>
                </div>
                <div className="modal-footer">
                <div className="d-flex justify-content-center">                  
                  <button className="btn-action btn-confirm" onClick={handleInativa}>
                    <div className="w-100 text-center">
                      <p>Confirmar</p>
                    </div>
                    <span>
                        <FaCheck />
                    </span>
                  </button>
                </div>
                </div>
            </div>            
        </div>
        {isVisibleWarning ? 
            <ModalWarning 
            close={() => setIsVisibleWarning(false)}
            title={titulo} 
            description={description} 
            /> 
        : null}            
            
        </>
    )
}

export default ModalDelete;