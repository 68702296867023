import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import './cad-clientes.css';
import Header from '../../../layouts/Header/Header';
import iconTitle from '../../../assets/images/icons/icon-cadastro-cliente.svg';
import iconSave from '../../../assets/images/icons/icon-salvar.svg';
import SideMenu from '../../../layouts/SideMenu/SideMenu';
import TitleSecundary from '../../../components/Titles/TitleSecundary/TitleSecundary';
import TitlePrimary from '../../../components/Titles/TitlePrimary/TitlePrimary';
import ModalWarning from '../../../components/Modals/ModalWarning/ModalWarning';
import ModalDelete from '../../../components/Modals/ModalDelete/ModalDelete';

export default class CadCliente extends Component {
  state = {
    name: '',
    cnpj: '',
    nome_fantasia: '',
    ie: '',
    im: '',
    tel: '',
    cep: '',
    ufs: [],
    selectedUf: '',
    cidades: [],
    selectedCity: '',
    endereco: '',
    numero: '',
    complemento: '',
    contato: '',
    sobrenome: '',
    cargo: '',
    email: '',
    tel_cont : '',
    ramal: '',
    cel: '',
    isToggleOn: false,
    gerPV: '',
    idPV: '',
    selectedPV: '',
    modalVisible: false,
    isVisibleDelete: false,
    modalTitle: '',
    modalDescription: '',
    idCliente: '',
    inativo: 0,
  }

  // chamado na construção da página
  componentDidMount() {

    axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response => {

        const ufInitials = response.data.map(uf => uf.sigla);

        this.setState({ufs: ufInitials});
      });

    const idEdit = this.props.match.params.id;
    this.setState({idCliente: idEdit});

    if(idEdit){
      axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/cliente/editCliente/${idEdit}`)
        .then(response => {
          console.log(response);          
          this.setState({cnpj: response.data[0].cnpj})
          this.setState({name: response.data[0].razao_social})
          this.setState({nome_fantasia: response.data[0].nome_fantasia})
          this.setState({ie: response.data[0].inscricao_estadual})
          this.setState({im: response.data[0].inscricao_municipal})
          this.setState({tel: response.data[0].telefone})
          this.setState({cep: response.data[0].cep})
          this.setState({selectedUf: response.data[0].estado_id})
          this.setState({selectedCity: response.data[0].cidade_id})
          this.setState({endereco: response.data[0].endereco})
          this.setState({numero: response.data[0].numero})
          this.setState({complemento: response.data[0].complemento})
          this.setState({contato: response.data[0].contato})
          this.setState({sobrenome: response.data[0].sobrenome})
          this.setState({cargo: response.data[0].cargo})
          this.setState({email: response.data[0].email})
          this.setState({tel_cont: response.data[0].tel_cont})
          this.setState({ramal: response.data[0].ramal})
          this.setState({cel: response.data[0].cel})
          this.setState({selectedPV: response.data[0].id_pv})
          this.setState({inativo: response.data[0].inativo})
          
          if (response.data[0].id_pv) {
            const toggleClick = document.querySelector('.checkbox-toggle');
            toggleClick.click();
          }
          
        });
    } else {
      axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response => {

        const ufInitials = response.data.map(uf => uf.sigla);

        this.setState({ufs: ufInitials});
      });
    }    
  }

  // chamdo quando algo é atualizado
  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedUf === '') {
      return;
    }
    if (prevState.selectedUf !== this.state.selectedUf) {
      axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.state.selectedUf}/municipios`)
          .then(response => {
              const cityNames = response.data.map(city => city.nome);

              this.setState({ cidades: cityNames});
          });
      }
  }

  handleChange = event => {

    const state = Object.assign({}, this.state);
    const nameInput = event.target.name;
    state[nameInput] = event.target.value;
    this.setState(state);
  }

  handleSelectedUf = event => {
    const uf = event.target.value;

    this.setState({ selectedUf: uf });
  }
  handleSelectedCity = event => {
    const city = event.target.value;

    this.setState({selectedCity: city});
  }
  handleSelectedPV = event => {
    const pv = event.target.value;

    this.setState({ selectedPV: pv });
  }

  handleClick = event => {
    const check = !this.state.isToggleOn;

    this.setState ({ isToggleOn: check });

    if (this.state.isToggleOn === false) {
      let cnpjClear = this.state.cnpj.replace('/', '').replace('-','').replace('.','');

      axios.get(`${process.env.REACT_APP_PV_BASE_URL}/api/v1/cobotics/geradorCnpj?cnpj=${cnpjClear.replace('.','')}`)
      .then(response => {

        const razao_social_PV = response.data.razao_social;
        const id_PV = response.data.id;

        this.setState({gerPV: razao_social_PV});
        this.setState({idPV: id_PV});
      });
    }
  }

  handleVoltar = event => {
    window.location.href = '/cliente';
  }

  handleSubmit = event => {
    event.preventDefault();

    const user = {
        razao_social: this.state.name,
        cnpj: this.state.cnpj,
        nome_fantasia: this.state.nome_fantasia,
        ie: this.state.ie,
        im: this.state.im,
        tel: this.state.tel,
        cep: this.state.cep,
        estado: this.state.selectedUf,
        cidade: this.state.selectedCity,
        endereco: this.state.endereco,
        numero: this.state.numero,
        complemento: this.state.complemento,
        contato: this.state.contato,
        sobrenome: this.state.sobrenome,
        cargo: this.state.cargo,
        email: this.state.email,
        tel_cont: this.state.tel_cont,
        ramal: this.state.ramal,
        cel: this.state.cel,
        rel_pv: this.state.selectedPV
    };
    console.log('idCliente vazio ' + this.state.idCliente);
    if (this.state.idCliente && this.state.inativo == 0) {
      axios.post(`${process.env.REACT_APP_IOT_BASE_URL}/cliente/updateCliente/${this.state.idCliente}`, { user })
      .then(res => {
        console.log(res.data)
        this.setState({ modalVisible: true })
        if(res.data == 'editado'){
          this.setState({ modalTitle: 'Concluído' })
          this.setState({ modalDescription: 'Cliente Editado com successo' })
        }
      })
      .catch(error => {
        console.log(error)
      })
    } else if(this.state.idCliente && this.state.inativo == 1){

      this.setState({ isVisibleDelete: true })
      // this.setState({ modalTitle: 'Concluído' })
      // this.setState({ modalDescription: 'Cliente ativado com successo' })

      // axios.get(`${process.env.REACT_APP_IOT_BASE_URL}/cliente/ativarCliente/${this.state.idCliente}`)
      //   .then(resp => {
      //     this.setState({ modalVisible: true })
      //     if(resp.data == 'ativado'){
      //       this.setState({ modalTitle: 'Concluído' })
      //       this.setState({ modalDescription: 'Cliente ativado com successo' })
      //     }
      // }).catch(error => {
      //   console.log(error)
      // })
    }else {
      axios.post(`${process.env.REACT_APP_IOT_BASE_URL}/cliente/save`, { user })
      .then(res => {
        console.log(res);
        console.log(res.data);
        this.setState({ modalVisible: true })

        if(res.data.resposta == 'cadastrado'){
          this.setState({ modalTitle: 'Aviso!' })
          this.setState({ modalDescription: 'CNPJ já cadastrado' })
        } else {          
          this.setState({ modalTitle: 'Concluído' })
          this.setState({ modalDescription: 'Cliente cadastrado com successo' })
        }
      }).catch((error) => {
        this.setState({ modalTitle: 'Houve um erro ao salvar o Cliente' })
        this.setState({ modalDescription: error.message || error })
      });
    }
    
  }

  render() {
    return (
      <section className="content">
        <Header primaryTitle={'Clientes'} secundaryTitle={'Cadastro de Clientes'}/>
        <div className="d-flex">
          <SideMenu />
          <div className="container">
              <TitlePrimary title="Cadastro de Cliente" />
              <form onSubmit={this.handleSubmit}>
                <div className="box-default">
                  <TitleSecundary title="Dados do Cliente"/>
                  <div className="row">
                    <div className="form-control col-md-2">
                      <label> CNPJ: *</label>
                      <InputMask className={this.state.inativo == 1 ? 'cancel' : ''} mask="99.999.999/9999-99" placeholder="__.___.___/____-__" type="text" name="cnpj" value={this.state.cnpj} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required />
                      
                    </div>
                    <div className="form-control col-md-5">
                      <label> Razão Social: *</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="name" value={this.state.name} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-5">
                      <label> Nome Fantasia: *</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="nome_fantasia" value={this.state.nome_fantasia} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-control col-md-4">
                      <label>Inscrição Estadual:</label>
                      <InputMask className={this.state.inativo == 1 ? 'cancel' : ''} mask="999.999.999.999"  placeholder="___.___.___.___" type="text" name="ie" value={this.state.ie} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""}/>
                    </div>
                    <div className="form-control col-md-4">
                      <label>Inscrição Municipal:</label>
                      <InputMask className={this.state.inativo == 1 ? 'cancel' : ''} mask="999.999.999.999"  placeholder="___.___.___.___" type="text" name="im" value={this.state.im} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""}/>
                    </div>
                    <div className="form-control col-md-4">
                      <label>telefone:</label>
                      <InputMask className={this.state.inativo == 1 ? 'cancel' : ''} mask="(99) 9999-9999" placeholder="(00) 0000-0000" type="text" name="tel" value={this.state.tel} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-control col-md-2">
                      <label>CEP: *</label>
                      <InputMask className={this.state.inativo == 1 ? 'cancel' : ''} mask="99.999-999"  placeholder="__.___-___" type="text" name="cep" value={this.state.cep} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-4">
                      <label htmlFor="uf">Estado (UF) *</label>
                      <select className={this.state.inativo == 1 ? 'cancel' : ''}
                          name="uf"
                          id="uf"
                          value={this.state.selectedUf}
                          onChange={this.handleSelectedUf}
                          disabled={this.state.inativo == 1 ? "disabled" : ""}
                          // ref={register}
                          required
                      >
                      <option value="" >Selecione uma UF</option>
                      {this.state.ufs.map(uf => (
                        <option key={uf} value={uf}>{uf} </option>
                      ))}
                      </select>
                    </div>
                    <div className="form-control col-md-6">
                      <label htmlFor="city">Cidade *</label>
                      <select className={this.state.inativo == 1 ? 'cancel' : ''}
                          name="city"
                          id="city"
                          value={this.state.selectedCity}
                          onChange={this.handleSelectedCity}
                          disabled={this.state.inativo == 1 ? "disabled" : ""}
                          //ref={register}
                          required
                      >
                          <option value="">Selecione uma cidade</option>
                          {this.state.cidades.map(city => (
                              <option key={city} value={city}>{city} </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-control col-md-7">
                      <label> Endereço: *</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="endereco" value={this.state.endereco} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-2">
                      <label> Numero: *</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="numero" value={this.state.numero} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-3">
                      <label> Complemento:</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="complemento" value={this.state.complemento} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-control col-md-4">
                      <label> Nome do Contato: *</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="contato"  value={this.state.contato} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-4">
                      <label> Sobrenome: *</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="sobrenome"  value={this.state.sobrenome} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-4 ">
                      <label> Cargo:</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="cargo"  value={this.state.cargo} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-control col-md-4">
                      <label> E-mail: *</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="email" name="email" value={this.state.email} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-3">
                      <label> Telefone: *</label>
                      <InputMask className={this.state.inativo == 1 ? 'cancel' : ''} mask="(99) 9999-9999" placeholder="(00) 0000-0000" type="text" name="tel_cont" value={this.state.tel_cont} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""} required/>
                    </div>
                    <div className="form-control col-md-2">
                      <label> Ramal:</label>
                      <input className={this.state.inativo == 1 ? 'cancel' : ''} type="text" name="ramal" value={this.state.ramal} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""}/>
                    </div>
                    <div className="form-control col-md-3">
                      <label> Celular:</label>
                      <InputMask className={this.state.inativo == 1 ? 'cancel' : ''} mask="(99) 99999-9999" placeholder="(00) 00000-0000" type="text" type="text" name="cel" value={this.state.cel} onChange={this.handleChange} disabled={this.state.inativo == 1 ? "disabled" : ""}/>
                    </div>
                  </div>
                </div>
                <div className="box-default">
                  <div className="title-secundary">
                    <h2> Vinculos</h2>
                  </div>
                  <div className="form-control flex-direction-row">
                  <label className="toggle">
                    <input type="checkbox" className="checkbox-toggle" defaultChecked={this.state.isToggleOn}
                    onChange={this.handleClick} />
                    <span className="slider-toggle round"></span>
                  </label>

                  <label> Vincular com a Plataforma Verde </label>
                  </div>
                  <div className="form-control col-md-12">
                    <label htmlFor="gerPV">Cliente na Plataformaverde</label>
                    <select className={this.state.inativo == 1 ? 'cancel' : ''}
                        name="gerPV"
                        id="gerPV"
                        value={this.state.selectedPV}
                        onChange={this.handleSelectedPV}
                        disabled={this.state.inativo == 1 ? "disabled" : ""}
                        // ref={register}
                    >
                    <option value="0" >Selecione um Cliente da PV</option>
                    <option value={this.state.idPV}>{this.state.gerPV}</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-space-between">
                  <a onClick={this.handleVoltar} className="btn-action btn-cancel">
                    <div className="w-100 text-center">
                    <p>Cancelar</p>
                    </div>
                    <span>x</span>
                  </a>
                  {this.state.idCliente && this.state.inativo == 0 &&
                    <button type="submit" className="btn-action btn-confirm">
                      <div className="w-100 text-center">
                        <p>Salvar Edição</p>
                      </div>
                      <span><img src={iconSave} /></span>
                    </button>
                  } 
                  {!this.state.idCliente &&
                    <button type="submit" className="btn-action btn-confirm">
                      <div className="w-100 text-center">
                        <p>Incluir e Salvar</p>
                      </div>
                      <span><img src={iconSave} /></span>
                    </button>
                  }
                  {this.state.idCliente && this.state.inativo == 1 && 
                    <button type="submit" className="btn-action btn-confirm">
                      <div className="w-100 text-center">
                        <p>Ativar</p>
                      </div>
                      <span><img src={iconSave} /></span>
                    </button>
                  } 
                </div>
              </form>
          </div>
        </div>
        {this.state.isVisibleDelete ? 
        <ModalDelete 
          close={() => this.setState({isVisibleDelete: false})} 
          id={this.state.idCliente}
          click={this.state.handleSubmit}
          // reason={reason}
          // justify={justify}
          // changeReason={(e) => setReason(e.target.value)}
          // count={count}
          title={`Ativar Cliente`}
          // changeJustify={(e) => countCharacter(e.target.value.length, e.target.value)}
        /> 
        : null
      }
        {this.state.modalVisible ? 
        <ModalWarning 
        title={this.state.modalTitle} 
        close={() => this.setState({modalVisible: false})}
        description={this.state.modalDescription}/> 
        : null}
        
      </section>
    )
  }
}